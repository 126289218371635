import { ChipmunkError, Model, Order, Sort } from 'types';
import { chipmunk } from 'utils';
import { notifications } from '@mantine/notifications';
import { errorHandler } from 'utils/error-handler';
import { productSchema } from 'pages/product/schemas';

type FetchProductParams = {
  product_ids: number[];
  include_deleted?: boolean;
  order?: Order;
  sort?: Sort;
};

type FetchProductOptions = {
  headers?: Record<string, string>;
  schema?: string;
};

export const fetchProducts = (params: FetchProductParams, options: FetchProductOptions = {}) => {
  const { schema = productSchema, headers = undefined } = options;

  return chipmunk.run(
    ({ action }) => action(Model.PRODUCTS, 'get', { params, schema, headers }),
    (e: ChipmunkError) => {
      // const impersonatedUser = useSessionStore.getState().impersonatedUser;
      // const message = impersonatedUser
      //   ? 'You are impersonated as another user. Product(s) might be unavailable because of access settings.'
      //   : '';
      if (e?.status === 404) {
        notifications.show({
          color: 'red',
          title: 'Product(s) cannot be found.',
          message: ``,
        });

        // TODO:  Navigate to homepage
        // router.navigate(publicOnlyRouteFallback);
        return;
      }
      return errorHandler(e, { title: 'Failed to load product(s)' });
    },
  );
};
