import React, { useEffect, useRef, useState } from 'react';
import { Routes } from 'routes';
import cx from 'classnames';
import moment from 'moment';
import { getThumbText, getType } from 'utils';
import ProductMetadata from './product-metadata';
import ProductBreadcrumbs from './product-breadcrumbs';
import { HorizontalThumbs } from '../index';
import { FetchActions, PRODUCTS_CLASSIFICATIONS, VIDEO_CLASSIFICATIONS, Recommendation, ItemId } from 'types';
import { IVideoplayerApi, VideoPlayer } from '@mediafellows/videoplayer';
import './style.scss';
import { sendPagePing } from 'utils';
import ProductShowTitle from './product-show-title';
import { useSessionStore } from 'store/session-store/session-store';
import { useBasicStore } from 'store/basic-store/basic-store';
import useSWR, { KeyedMutator } from 'swr';
import { fetchOtherVideos } from 'utils/api/fetch-other-videos';
import { fetchRecommendationProducts } from 'utils/api/fetch-recommendation-products';
import { fetchRecommendations } from 'utils/api/fetch-recommendations';
import { fetchAllProductDataResult } from 'utils/api/fetch-all-product-data';
import { fetchVideoURL } from 'utils/api/fetch-video-url';
import { useHistory } from 'react-router-dom';
import { fetchProducts } from 'utils/api/fetch-products';

const formatDuration = (period) => {
  const parts = [];
  const duration = moment.duration(period, 'seconds');

  if (!duration) return '';

  if (duration.hours() >= 1) {
    const hours = Math.floor(duration.hours());
    parts.push(`${hours} h`);
  }

  if (duration.minutes() >= 1) {
    const minutes = Math.floor(duration.minutes());
    parts.push(`${minutes} min`);
  }

  if (!parts.length && duration.seconds() >= 1) {
    const seconds = Math.floor(duration.seconds());
    parts.push(`${seconds} sec`);
  }

  return parts.join(' ');
};

export const humanizeDuration = (minutes, times?) => {
  if (!times) times = 1;
  const value = Number(minutes);

  if (value === 0) return '';
  if (value <= 30) return times === 1 ? 'Half Hour' : `${times} x Half Hour`;

  const multiplier = Math.ceil(value / 60);
  const words = {
    1: 'One',
    2: 'Two',
    3: 'Three',
    4: 'Four',
    5: 'Five',
    6: 'Six',
    7: 'Seven',
    8: 'Eight',
    9: 'Nine',
    10: 'Ten',
  };

  const duration = multiplier === 1 ? `${words[multiplier]} Hour` : `${words[multiplier]} Hours`;

  return times === 1 ? duration : `${times} x ${duration}`;
};

const getProductThumbText = (asset) => {
  const { name, duration, classification } = asset || {};

  let title = asset?.name;
  let content = `${formatDuration(duration)}`;

  if (name.includes('Episode')) {
    content = name.replace('Episode', 'Ep');
  }

  if (name.includes('"')) {
    const regex = /"(.*?)"/g;
    const found = name.match(regex);

    if (found) {
      content = content.replace(found[0], '');
      content = `${content}, ${formatDuration(duration)}`;
      title = found[0];
    }
  } else {
    content = `${content}, ${VIDEO_CLASSIFICATIONS[classification]}`;
  }

  content = content.replace(' , ', ', ');

  return {
    title: content,
    content: title,
  };
};

type ProductShowProps = {
  recommendationProductId?: ItemId;
  className?: string;
  productData?: fetchAllProductDataResult;
  mutate?: KeyedMutator<fetchAllProductDataResult>;
  recommendation?: Recommendation;
};

enum Tabs {
  FEATURED = 'featured',
  SCREENERS = 'screeners',
  TRAILERS = 'trailers',
}

const shouldShowScreeners = (seasons, screeners) => {
  const seriesScreeners = seasons?.some((season) => season.screeners.length);
  return Boolean(seriesScreeners || screeners.length);
};

const shouldShowTrailers = (seasons, trailers) => {
  const seriesTrailers = seasons?.some((season) => season.trailers.length);
  return Boolean(seriesTrailers || trailers.length);
};

export const ProductShow = (props: ProductShowProps) => {
  const history = useHistory();
  const { productData, recommendation } = props;
  const { product, similarProducts, screeners, trailers, featuredVideo, seasons, allVideos, episodes_count } =
    productData || {};
  const { data } = useSWR(
    seasons && allVideos ? [FetchActions.PRODUCT_OTHER_VIDEOS, seasons, allVideos] : null,
    ([, seasons, allVideos]) => fetchOtherVideos(seasons, allVideos),
  );

  const relatedProductIds = product.meta.related_product_ids;

  const { data: relatedProductsData } = useSWR(
    relatedProductIds ? ['related_products', relatedProductIds] : null,
    ([, relatedProductIds]) => fetchProducts({ product_ids: relatedProductIds }),
  );

  const relatedProducts = relatedProductsData?.objects || [];

  const { screenerOtherVideos, trailerOtherVideos } = data || {};

  const [activeTab, setActiveTab] = useState(shouldShowScreeners(seasons, screeners) ? Tabs.SCREENERS : Tabs.TRAILERS);
  const player = useRef<HTMLDivElement & IVideoplayerApi>(null);

  const { data: recommendations = [] } = useSWR(FetchActions.RECOMMENDATIONS, fetchRecommendations);

  const recommendationToken = recommendations.find((item) => item.id === Number(recommendation?.id))?.token ?? null;

  const productIds = [...new Set(recommendations.flatMap((item) => item.product_ids).filter(Boolean))];

  const { data: products = [] } = useSWR(
    recommendation && productIds.length ? [FetchActions.RECOMMENDATION_PRODUCTS, productIds] : null,
    ([, productIds]) => fetchRecommendationProducts(productIds),
  );

  const categories = useBasicStore((state) => state.categories);

  const user = useSessionStore((state) => state.user);
  const isAuthenticated = useSessionStore((state) => state.session?.isAuthenticated);

  const [videoAsset, setVideoAsset] = useState(featuredVideo);

  const { data: videoURL } = useSWR(videoAsset ? [FetchActions.ASSET_VIDEO_URL, videoAsset.id] : null, () =>
    fetchVideoURL(videoAsset.id),
  );

  const url = videoURL?.url || videoURL?.hls;
  const firstViableSeasonScreener = seasons?.find((season) => season.screeners.length);
  const firstViableSeasonTrailer = seasons?.find((season) => season.trailers.length);

  const [videoAutoplay, setVideoAutoplay] = useState(false);
  const [assets, setAssets] = useState(
    shouldShowScreeners(seasons, screeners)
      ? firstViableSeasonScreener?.screeners || screeners
      : firstViableSeasonTrailer?.trailers || trailers,
  );

  const [activeSeason, setActiveSeason] = useState(
    seasons?.length ? firstViableSeasonScreener || firstViableSeasonTrailer : null,
  );
  const [cachedSeason, setCachedSeason] = useState({
    screener: null,
    trailer: null,
  });
  const [isOtherVideosActive, setIsOtherVideosActive] = useState(seasons?.length ? !Boolean(activeSeason) : false);
  const { default_layer } = product || {};
  const { duration, year_of_production } = default_layer?.meta || {};
  const fallbackPreview = product?.inherited_preview_image?.url;

  const customDuration = humanizeDuration(duration, episodes_count || 0);

  useEffect(() => {
    if (seasons?.length && isOtherVideosActive) {
      setAssets(screenerOtherVideos?.length ? screenerOtherVideos : trailerOtherVideos);
    }
    if (isOtherVideosActive) return;
    if (activeTab === Tabs.SCREENERS) {
      setAssets(activeSeason?.screeners || screeners);
    } else {
      setAssets(activeSeason?.trailers || trailers);
    }
  }, [
    activeSeason?.screeners,
    activeSeason?.trailers,
    activeTab,
    isOtherVideosActive,
    screenerOtherVideos,
    screeners,
    seasons?.length,
    trailerOtherVideos,
    trailers,
  ]);

  if (!productData.product) {
    return null;
  }

  const handleTabClick = (e) => {
    const tab = e.target.dataset.tab;

    setActiveSeason(
      tab === Tabs.TRAILERS
        ? cachedSeason.trailer || firstViableSeasonTrailer
        : cachedSeason.screener || firstViableSeasonScreener,
    );

    setActiveTab(tab);
  };

  const handleSeasonChange = async (e) => {
    const id = e.currentTarget.id;
    const season = seasons.find((s) => s.id === Number(id));

    setCachedSeason({
      screener: activeTab === Tabs.SCREENERS ? season : cachedSeason.screener,
      trailer: activeTab === Tabs.TRAILERS ? season : cachedSeason.trailer,
    });

    setActiveSeason(season);
    setIsOtherVideosActive(false);
  };

  const handleOtherVideosClick = async () => {
    setIsOtherVideosActive(true);
    if (activeTab === Tabs.SCREENERS) {
      setAssets(screenerOtherVideos);
    } else {
      setAssets(trailerOtherVideos);
    }
  };

  const handleRecommendationClick = async (i, product_id) => {
    const url = `${Routes.RECOMMENDATIONS_PRODUCT}/${recommendationToken}/${product_id}`;
    history.push(url);
  };

  const filteredRecommendations = products?.filter(
    (recoProduct) => recoProduct.id !== product?.id && recommendation?.product_ids?.includes(recoProduct.id),
  );

  const recoRecipient = recommendation?.email_address;

  sendPagePing('visited', {
    entity_type: 'product',
    entity_id: product?.id,
  });

  return (
    <div className="product-show container-fluid--custom pt-13 pt-lg-14">
      {!recommendation && (
        <div>
          <ProductBreadcrumbs product={product} categories={categories} />
        </div>
      )}

      <ProductShowTitle duration={customDuration} product={product} className="d-lg-none" />
      <div className="row">
        <div className="col-12 col-lg-6 mt-lg-0 order-lg-1">
          {videoAsset && (
            <div className="position-relative">
              {url && !url?.includes('watermark-cdn') && !user?.disable_watermark && (
                <>
                  {user && <span className="email-watermark">{user.email}</span>}
                  {!user && recommendation && recoRecipient && <span className="email-watermark">{recoRecipient}</span>}
                  {/* TODO: Temporary solution - remove it when we have recipient email from BE */}
                  {!user && recommendation && <span className="email-watermark">{recommendation.subject}</span>}
                </>
              )}
              <VideoPlayer
                ref={player}
                video={videoAsset}
                autoplay={videoAutoplay}
                posterUrl={videoAsset?.preview_image?.url}
              ></VideoPlayer>
            </div>
          )}
          {!videoAsset && fallbackPreview && (
            <div className="text-center">
              <img src={fallbackPreview} className="img-fluid" alt={product?.title} />
            </div>
          )}
        </div>

        <div className="product-show__product-tabs col-12 d-flex align-items-center mb-1 mt-3 mt-lg-3 order-lg-3">
          {shouldShowScreeners(seasons, screeners) && (
            <div
              className={cx('custom-link product__btn me-5', { 'product__btn--active': activeTab === Tabs.SCREENERS })}
              role="button"
              data-tab={Tabs.SCREENERS}
              onClick={handleTabClick}
            >
              Screeners
            </div>
          )}
          {shouldShowTrailers(seasons, trailers) && (
            <div
              className={cx('custom-link product__btn', { 'product__btn--active': activeTab === Tabs.TRAILERS })}
              role="button"
              data-tab={Tabs.TRAILERS}
              onClick={handleTabClick}
            >
              Trailers & More
            </div>
          )}
        </div>

        <div className="col-12 d-flex align-items-center mt-1 mb-2 flex-wrap order-lg-5">
          {!!seasons?.length &&
            seasons.map((season) => {
              const { sequence_number, id } = season;
              if (
                (activeTab === Tabs.SCREENERS && season.screeners.length) ||
                (activeTab === Tabs.TRAILERS && season.trailers.length)
              ) {
                return (
                  <div
                    onClick={handleSeasonChange}
                    id={id}
                    className={cx('custom-link product__btn me-5 mb-3', {
                      'product__btn--active': activeSeason?.id === id && !isOtherVideosActive,
                    })}
                    role={'button'}
                    key={id}
                  >
                    Season {sequence_number}
                  </div>
                );
              }
            })}
          {PRODUCTS_CLASSIFICATIONS[product.type] && activeTab !== Tabs.SCREENERS && !!trailerOtherVideos?.length && (
            <div
              onClick={handleOtherVideosClick}
              className={cx('custom-link product__btn me-5 mb-3', {
                'product__btn--active': isOtherVideosActive,
              })}
              role={'button'}
            >
              {PRODUCTS_CLASSIFICATIONS[product.type]}
            </div>
          )}
        </div>

        <div className="col-12 order-lg-last">
          {Boolean(assets?.length) && (
            <HorizontalThumbs
              className="product__thumbs"
              scale="vga"
              onClick={(i, item) => {
                setVideoAutoplay(true);
                setVideoAsset(item);
              }}
              items={assets}
              texts={assets.map(getProductThumbText)}
            />
          )}
        </div>

        <div className="product__spacing d-block d-lg-none"></div>
        <div className="d-block d-lg-none"></div>

        <div className="col-12 col-lg-6 position-relative product-show__metadata-wrapper mt-0 mt-lg-0 order-lg-2">
          <ProductShowTitle duration={customDuration} product={product} className="d-none d-lg-block" />
          <ProductMetadata
            className="order-lg-2"
            data={product}
            year_of_production={year_of_production}
            productType={getType(default_layer?.meta.product_type).toLowerCase()}
          />
        </div>

        {recommendation && Boolean(filteredRecommendations.length) && (
          <div className="col-12 mt-5 mt-lg-8 order-lg-last">
            <h3 className="text-capitalize mb-5">Other titles in this list</h3>
            <HorizontalThumbs
              scale="vga"
              onClick={handleRecommendationClick}
              items={filteredRecommendations}
              texts={filteredRecommendations.map((item) => getThumbText(item))}
            />
          </div>
        )}

        {Boolean(similarProducts?.length && isAuthenticated) && (
          <div className="product-show__recommendations col-12 mt-3 mt-lg-4 order-lg-last">
            <h3 className="text-capitalize mb-5">Recommended</h3>
            <HorizontalThumbs
              scale="vga"
              onClick={(i, item) => {
                history.push(`${Routes.PRODUCT}/${item.id}`);
              }}
              items={similarProducts}
              texts={similarProducts.map((item) => getThumbText(item))}
            />
          </div>
        )}
        {Boolean(relatedProducts?.length && isAuthenticated) && (
          <div className="related-products__thumbs col-12 mt-3 mt-lg-4 order-lg-last">
            <h3 className="text-capitalize mb-5">More Like This</h3>
            <HorizontalThumbs
              scale="vga"
              onClick={(i, item) => {
                history.push(`${Routes.PRODUCT}/${item.id}`);
              }}
              items={relatedProducts}
              texts={relatedProducts.map((item) => getThumbText(item))}
            />
          </div>
        )}
      </div>
    </div>
  );
};
