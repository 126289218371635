import { assetListSchema, productShowSchema } from '@mediafellows/tuco/dist/lib/schemas';

export const productSchema = `
  type,
  id,
  title,
  display_title,
  sequence_number,
  series_count,
  seasons_count,
  episodes_count,
  preview_asset_id,
  inherited_preview_asset_id,
  inherited_preview_image,
  preview_image,
  parent {
    type,
    sequence_number,
    display_title,
    parent { 
      display_title
      }
    },
  preview_asset {
    elements
  },
  default_layer {
    meta
  },
  meta
`;

export const marketingAssetSchema = `
  @type,
  id, effective_permissions, classification, name,
  duration, protection_levels, preview_image_id,
  preview_image { url, distribution_url, signing_key },
  default_layer { id, description, notes }
`;
export const productVideosAssetSchema = `
  id, name, classification, duration, parent_id,
  products,
  languages { name },
  preview_image
`;

export const productSchemas = {
  product: productSchema.replace('logline,', 'logline,product_type,rating,'),
  asset: marketingAssetSchema,
  marketingAsset: marketingAssetSchema,
};

export const recommendationsSchemas = {
  product: productShowSchema,
  asset: assetListSchema,
  marketingAsset: assetListSchema,
};
